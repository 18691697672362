/* Chrome, Safari, Edge, Opera */
/* Firefox */

.jobs {
  h1 {
    text-align: center;
  }
  h4 {
    text-align: center;
    color: var(--primary-gray);
  }
  .__shecodeheader_title {
    h1 {
      font-size: 45px;
    }
  }
  .sca-btn-small {
    box-shadow: 0px 3px 6px #00000029;
  }
  .__shecodeheader_subtitle {
    h4 {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .__shecodeheader_text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.sca-btn-small {
  width: auto;
}
.container.width-100 {
  padding-top: 5rem;
}
.job-type-header {
  padding-bottom: 25px;
}

.role-apply {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  padding-top: 30px;
  padding-bottom: 50px;
}
.apply {
  display: flex;
  button {
    margin-top: 0;
    padding: 10px 25px;
  }
}
.role-desc {
  margin-top: 2rem;
  color: var(--primary-gray);
}
.level {
  grid-area: level;
}
.industry {
  grid-area: industry;
}
.employment-type {
  grid-area: employment-type;
}
.salary-range {
  grid-area: salary-range;
}
.grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto 25% auto;
  grid-template-areas: "level industry" ". ." "employment-type salary-range";
}
div.grid-container {
  &:nth-last-child(1) {
    margin-bottom: 70px;
  }
  &:nth-last-child(2) {
    margin-bottom: 70px;
  }
}
.__jobdeets_header {
  color: var(--primary-black);
  font-weight: bolder;
  margin-bottom: 5px;
}
.__jobdeets_body {
  color: var(--primary-gray);
  margin-bottom: 5px;
}

.margin-top-2r {
  margin-top: 2rem;
}
.job-status {
  display: inline-block;
  padding: 10px 25px;
  background-color: #dcdcdc;
  margin-right: 20px;
}

p.post-a-job-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
select {
  &:invalid {
    font-family: Roboto, sans-serif;
    color: #1a1a1a8a;
  }
  option[value=""] {
    font-family: Roboto, sans-serif;
    color: #1a1a1a8a;
  }
}

.jobType {
  font-size: 15px;
  font-style: italic;
}
@media screen and (max-width: 768px) {
  .jobs {
    .__shecodeheader_text {
      h4 {
        margin-bottom: 0 !important;
      }
    }
    .__shecodeheader_subtitle {
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 424px) {
  .jobs {
    .__shecodeheader_text {
      margin-bottom: 50px;
    }
  }
  .__jobs_preview {
    .col-xs-12 {
      padding: 0;
    }
  }
}

@media screen and (max-width: 425px) {
  .first_ring {
    border: 5px solid var(--primary-main-pink);
    width: 17px;
    height: 17px;
  }

  .job-status {
    margin-right: 10px;
  }
}
@media screen and (max-width: 375px) {
  .first_ring {
    width: 19px;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .jobs-detail-container {
    width: 100%;
    height: 100vh;
  }
}
