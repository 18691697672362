/*=================================================================
	Basic Setup css
==================================================================*/
/* @font-face {
    font-family: 'Raleway-Black';
    src: url('../fonts/Raleway/Raleway-Black.ttf');
} */
/* Internet Explorer 10 in Windows 8 and Windows Phone 8 Bug fix */
/*hover on dropdown and drop*/
/*hover on dropdown and drop end*/
/*=================================================================
Custom Css
==================================================================*/
/* button */
/* page header/banner */
/*footer*/
/*footer end*/
/* home */
/* .counter .counter_items {
    margin-top: 10%;
} */
/* team */
/* code of conduct begins */
/* code of conduct ends */
/*faq starts*/
/* faq ends */
/* toaster/alert */
/* donation prompt modal */
html {
  font-size: 100%;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  overflow-x: hidden;
  line-height: 20px;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

body {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  color: #222222;
  font-weight: normal;
  overflow-x: hidden;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  outline: none;

  &:active {
    text-decoration: none;
    outline: none;
  }

  &:hover {
    text-decoration: none;
    outline: none;

    text-decoration: none !important;
  }

  &:focus {
    text-decoration: none;
    outline: none;

    text-decoration: none !important;
  }

  &:visited {
    text-decoration: none;
    outline: none;
  }
}

button {
  &:focus {
    outline: none;
  }
}

article {
  display: block;
}

figure {
  display: block;
}

footer {
  display: block;
}

header {
  display: block;
}

nav {
  display: block;
}

section {
  display: block;
}

select {
  &:focus {
    outline-offset: 0;
  }
}

p {
  margin: 0 0 10px;
}

h4 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

img {
  vertical-align: middle;
  display: inline;
}

h1,
h2,
h3,
h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: initial;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #a7a7a7;
}

.section-whitespace-top {
  margin-top: 10rem;
}

.not-found {
  background-color: #eceff5;
}

.sca-btn {
  width: 100%;
  padding: 8px 45px;
  border-radius: 5px 5px 5px 5px;
  font-size: 15px;
  font-weight: 400;
  margin-top: 5%;
}

.pink-btn {
  color: #ffffff !important;
  background: var(--primary-main-pink);

  &:hover {
    color: var(--primary-main-pink) !important;
    background: #fff;
    border: 1px solid var(--primary-main-pink);
  }
}

.white-btn {
  color: var(--primary-main-pink) !important;
  background: #fff;
  border: 1px solid var(--primary-main-pink);

  &:hover {
    color: #ffffff !important;
    background: var(--primary-main-pink);
  }
}

.contact-bg {
  background-image: url("../images/contact-bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.contact-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(183, 5, 105, 0.6);
  z-index: 1;
}

.contact-bg .content {
  position: relative;
  z-index: 2; 
}


.__shecodelogo {
  width: 48px;
}

.shecode_button {
  padding: 8px 45px;
  color: #ffffff !important;
  font-size: 20px;
  margin-top: 5%;
  background: var(--primary-main-pink) !important;
  transition: all 0.2s;

  &:hover {
    box-shadow: 1px 4px 8px #ac1d6db3;
  }
}

.__shecodeheader {
  margin: 3% 0;
}

.__shecodeheader_title {
  h1 {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: var(--primary-dark-pink);
  }
}

.__shecodeheader_subtitle {
  h4 {
    text-align: left;
    font-size: 25px;
    letter-spacing: 0.76px;
    margin-top: 30px;
    font-weight: 400;
  }
}

.__shecodeheader_image {
  height: 450px;
  background: transparent url(../images/teambanner.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.__footerdownbg {
  background: var(--secondary-main-black);
  color: #ffffff;
  font-size: 14px;
  padding: 13px;
  text-align: center;
}

.__shecode_subtopic {
  text-align: center;
  font-size: 24px;
  line-height: 27px;
}

.__shecode_subtitle {
  text-align: inherit;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

.text-box {
  float: right;

  .__shecode_title {
    color: var(--secondary-main-black) !important;
  }
}

.__shecode_title {
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 1.44px;
  left: 50%;
  margin-top: 16%;
  color: var(--primary-dark-pink);
  line-height: 40px;

  &:before {
    content: "";
    display: block;
    width: 15%;
    border-bottom: 3px solid var(--primary-main-pink);
  }
}

.about_section {
  width: 85%;
  // float: right;
  padding: 20px;
  margin: 30px 0;

  .__shecode_title {
    color: var(--secondary-main-black);
  }

  .about_values {
    display: flex;
    justify-content: space-around;
    margin-top: 67px;

    div {
      width: 25%;
      text-align: inherit;

      img {
        width: 64px;
        margin-bottom: 10px;
      }
    }

    h4 {
      text-align: inherit;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0px;
      color: var(--primary-main-pink);
    }

    p {
      text-align: left;
      font-size: 15px;
      letter-spacing: -0.05em;
      font-weight: 400;
      color: var(--secondary-main-black);
    }
  }
}

.counter {
  width: 100%;
  height: 200px;
  background: #eeeef0 0% 0% no-repeat padding-box;
  padding: 20px;
  margin: 60px 0;

  .__shecode_title {
    margin-top: 10%;
    font-size: 35px;
  }

  .counter_items {
    .slick-track {
      .slick-slide {
        div {
          .slick-item {
            display: flex !important;
            margin-right: 30px;
            margin-top: 20px;

            h4 {
              text-align: left;
              font-weight: bolder;
              font-size: 40px;
              color: #b70569;
              opacity: 1;
            }

            p {
              color: #273029;
              margin: 32px 0;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.contact_section {
  width: 100%;

  h4 {
    color: var(--primary-black);
  }
}

.contact-form-textarea {
  min-width: 70%;
  padding: 1rem;
}

.contact-form-textarea::placeholder {
  font-size: 12px;
}

.highlight {
  color: var(--primary-main-pink);
}

.__shecodeteammember_box {
  border-radius: 20px 20px 0px 0px;
  width: 250px;
  height: 258px;
  margin-bottom: 30px;
  position: relative;
  margin: 30px auto;
  cursor: pointer;
}

.__shecodeteammember_img {
  width: 100%;
  height: 100%;

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px 24px 0px 0px;
  }
}

.__shecodeteammember_text {
  background: var(--primary-main-pink) 0% 0% no-repeat padding-box;
  width: 100%;
  position: absolute;
  bottom: 0;

  .name {
    text-transform: capitalize;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .role {
    text-align: center;
    font-size: 12px;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    margin-top: 3px;
  }
}

.text-primary-color {
  color: var(--primary-dark-pink);
}

.font-weight-bolder {
  font-weight: bolder;
}

.code-of-conduct {
  ul {
    list-style: none;

    li {
      &::before {
        content: "\2022";
        color: var(--primary-dark-pink);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 20px;
      }
    }
  }
}

.container {
  h3 {
    margin-bottom: 5px;
  }
}

.panel-title {
  > a {
    &:before {
      float: right !important;
      font-family: FontAwesome;
      content: "\f068";
      padding-right: 5px;
    }

    &:hover {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }

  > a.collapsed {
    &:before {
      float: right !important;
      content: "\f067";
    }
  }
}

.donation-faq {
  margin-top: 30px;

  p {
    display: flex;
  }

  ol {
    li {
      margin-bottom: 10px;
    }
  }
}

.sub_ol {
  margin-right: 15px;
  font-weight: 600;
}

.privacy-intro {
  margin-top: 30px;
}

#prompt {
  img {
    text-align: center;
  }

  h3 {
    text-align: center;
    font: normal normal medium 32px/32px Poppins;
    letter-spacing: 0px;
    color: #b70569;
    margin: 30px 0;
  }

  p {
    text-align: center;
    font: normal normal normal 20px/44px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
    margin-bottom: 0;
    line-height: 25px;
    margin-top: 20px;
  }

  .modal-body {
    padding: 30px;

    .close-div {
      margin-bottom: 30px;
    }

    .close {
      opacity: 1;

      &:hover {
        opacity: 0.2;
      }

      img {
        width: 35px;
      }
    }
  }

  .prompt-body {
    margin-top: 52px;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .section-whitespace-top {
    margin-top: 7rem;
  }

  .sca-btn {
    padding: 15px 17px;
  }
}

@media screen and (max-width: 767px) {
  .section-whitespace-top {
    margin-top: 5rem;
  }

  footer {
    .__shecodelogo {
      display: none;
    }
  }

  .contact-form-textarea {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .section-whitespace-top {
    margin-top: 0rem;
  }

  .contact-form-textarea {
    width: 100%;
  }
}

@media (min-width: 768px) {
  #prompt {
    .modal-dialog {
      width: 600px;
      margin: 10% auto;
    }
  }
}
