html {
  scroll-behavior: smooth;
}

#search {
  width: 600px;
  text-align: center;
  margin: auto;
  position: relative;

  .search-button {
    position: absolute;
    top: 37px;
    right: 23%;
    width: 10px;
  }

  input[type="text"] {
    width: 350px;
    height: 40px;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0;
    margin: 20px auto;
    font-size: 11px;
    padding: 10px;
  }
}

.nav {
  > li {
    > a {
      padding: 0;
    }
  }
}

.tab-content {
  margin-top: 30px;

  .box {
    width: 180px;
    height: 80px;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 8px 0px;
    padding: 6px 20px;
    margin-bottom: 15px;

    h5 {
      color: #000000;
      text-transform: uppercase;
      font-weight: bold;
    }

    p {
      color: #1a1a1acc;
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 1025px) {
  .tab-content {
    .box {
      h5 {
        color: #000000;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 376px) {
  #search {
    width: auto;
    text-align: center;
    margin: auto;

    img {
      position: absolute;
      top: 37px;
      right: 10%;
      width: 10px;
    }
  }
}

@media screen and (max-width: 375px) and (min-width: 321px) {
  #search {
    width: auto;
    text-align: center;
    margin: auto;

    input {
      width: 280px;
    }

    img {
      position: absolute;
      top: 37px;
      right: 10%;
      width: 10px;
    }
  }

  .tab-content {
    .box {
      width: auto;
    }
  }
}

@media screen and (max-width: 320px) {
  #search {
    width: auto;
    text-align: center;
    margin: auto;

    input {
      width: 280px;
    }

    img {
      position: absolute;
      top: 37px;
      right: 4%;
      width: 10px;
    }
  }

  .tab-content {
    .box {
      width: auto;
    }
  }
}
