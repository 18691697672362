.box {
  margin: auto 20px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  _height: 50px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  h5 {
    line-height: 48px;
    margin: 0;
  }
  &:hover {
    background: #eacfe029 0% 0% no-repeat padding-box;
    border: 1px solid #6c003e;
    border-radius: 4px;
  }
  &:focus {
    background: #eacfe029 0% 0% no-repeat padding-box;
    border: 1px solid #6c003e;
    border-radius: 4px;
  }
}
.box.active {
  background: #eacfe029 0% 0% no-repeat padding-box;
  border: 1px solid #6c003e;
  border-radius: 4px;
}
.filter {
  span {
    font-style: italic;
    color: #b70569;
  }
}
.acct-details {
  border: 1px solid #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 4px 8px #e9e7e7;
}
.mini-title {
  text-align: left;
  font-weight: 500;
}

.__partner-donate {
  h4 {
    font-weight: bold;
    letter-spacing: 0.56px;
  }
  div.desc {
    padding-top: 1rem;
  }
  > div {
    width: 25%;
    _height: 485px;
    opacity: 1;
    _margin: 0 0;
    padding: 2rem;
    text-align: center;
    position: relative;
    background-color: #fff;
    border-radius: 24px 24px 24px 0px;
    box-shadow: 0px 3px 12px #00000029;
  }
}
.cta {
  display: flex;
  justify-content: space-between;
  position: absolute;
  flex-wrap: wrap;
  bottom: 3rem;
  left: 2rem;
  right: 2rem;
}
.__partner-donate_donate-cta {
  a {
    font-size: 11px;
  }
  .sca-btn {
    // padding: 15px 16px;
  }
}
#laptop-fund {
  width: 100%;
}
.fund {
  font-family: Montserrat;
  font-size: 39px;
  font-weight: bold;
  color: var(--primary-dark-pink);
  text-align: center;
  margin: 0px;
}
@media screen and (max-width: 1440px) and (min-width: 1025px) {
  .__partner-donate {
    > div {
      _height: 500px !important;
      width: 33% !important;
    }
  }
  .__partner-donate_donate-cta {
    a {
      font-size: 12px;
      width: 48%;
    }
  }
}
@media screen and (max-width: 1150px) and (min-width: 1024px) {
  .__partner-donate {
    > div {
      width: 40% !important;
    }
  }
  .__partner-donate_donate-cta {
    a {
      font-size: 11px;
      width: 48%;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .__partner-donate {
    > div {
      width: 45%;
      _margin: 0 12px;
    }
  }
  .__partner-donate_donate-cta {
    a {
      font-size: 9px;
    }
  }
}
@media screen and (max-width: 1005px) and (min-width: 768px) {
  .__partner-donate {
    > div {
      _height: 540px;
    }
  }
}
@media screen and (max-width: 767px) and (min-width: 426px) {
  .__partner-donate {
    > div {
      width: 60%;
      _height: 540px;
    }
  }
}

@media screen and (max-width: 767px) {
  .cta {
    flex-wrap: wrap;
  }
  .__partner-donate {
    > div {
      width: 95%;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1150px) and (min-width: 1024px) {
    .__partner-donate {
      > div {
        width: 40% !important;
        height: 570px !important;
      }
    }

    .__partner-donate_donate-cta {
      _height: 600px;
    }
  }
  .__partner-donate_donate-cta {
    a {
      font-size: 15px;
    }
  }
  .box {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 320px) {
  #laptop-fund {
    font-size: 14px;
  }
}
