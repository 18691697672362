:root {
	--primary-main-pink: #B70569;
	--primary-dark-pink: #6D033F;
	--primary-light-pink: #F0CDE1;
	--secondary-main-black: #1A1A1A;
	--primary-black: #000000;
	--primary-lighter-pink: #F0CDE14D;
	--primary-very-light-pink: #F7E6F04D;
	--primary-white: #ffffff;
	--primary-gray: #707070;
}
