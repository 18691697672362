@media screen and (max-width: 1024px) and (min-width: 768px) {
  .counter {
    height: 200px !important;
    .__shecode_title {
      margin-top: 10% !important;
      font-size: 28px !important;
    }
  }
  .__shecode_title {
    font-size: 40px !important;
  }
  .__shecodeheader_subtitle {
    h4 {
      font-size: 25px !important;
    }
  }
  .about_section {
    .sub-text {
      font-size: 11px !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .counter {
    float: right;
  }
}

@media screen and (max-width: 768px) and (min-width: 375px) {
  .counter {
    .__shecode_title {
      margin-top: 10% !important;
      font-size: 25px !important;
    }
  }

  .__shecodeheader_title {
    h1 {
      font-size: 40px !important;
    }
  }

  .__shecode_subtopic {
    font-size: 15px !important;
  }
  .__shecode_title {
    font-size: 25px !important;
  }
  .__shecode_subtitle {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .about_section {
    .sub-text {
      font-size: 12px;
    }
    width: 95% !important;
    .about_values {
      p {
        font-size: 12px !important;
      }
    }
  }
  .nav {
    > li {
      > a {
        padding: 5px;
      }
    }
  }

  .__shecodeheader_subtitle {
    h4 {
      font-size: 20px !important;
      margin-top: 20px !important;
    }
  }
  .__shecodeheader_image {
    height: 300px;
  }
  .counter {
    .counter_items {
      .slick-slide {
        h4 {
          font-size: 30px;
        }
        p {
          margin: 23px 0;
          font-size: 11px;
        }
      }
    }
    height: 250px !important;
  }
}
@media screen and (max-width: 480px) {
  .__shecodeheader_text {
    margin-top: 0 !important;
  }
  .__shecodeheader_title {
    h1 {
      font-size: 35px !important;
    }
  }
  .__shecodeheader_subtitle {
    h4 {
      font-size: 18px !important;
      margin-top: 20px !important;
    }
  }
  .__shecodeheader_image {
    height: 0 !important;
  }
  .about_section {
    width: 100% !important;
    .about_values {
      display: block !important;
      div {
        width: 100% !important;
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }
  .contact_section {
    width: 100%;
  }

  .counter {
    height: 280px !important;
  }
}
@media screen and (max-width: 375px) {
  .counter {
    height: 320px !important;
  }
}
@media screen and (max-width: 320px) {
  .counter {
    .__shecode_title {
      margin-top: 10%;
      font-size: 25px !important;
    }
  }

  .__shecodeheader_subtitle {
    h4 {
      font-size: 15px !important;
      margin-top: 15px !important;
      margin-bottom: -38px;
    }
    margin-bottom: 30px;
  }

  .__shecode_subtopic {
    font-size: 15px !important;
  }
  .__shecode_title {
    font-size: 25px !important;
  }
  .__shecode_subtitle {
    font-size: 15px !important;
  }
}
