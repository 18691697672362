.holder {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  background-color: var(--primary-white);
  padding: 20px;
  width: 80%;
  max-width: 500px;
  text-align: center;
  border-radius: 24px;
  height: 575px;
}
.img404 {
  background: transparent url("../images/404.svg") no-repeat;
  background-size: 100%;
  height: 404px;
}
.errorhead {
  text-align: center;
  font-weight: bold;
  font-size: 45px;
  color: #585858;
  letter-spacing: 1.44px;
  padding-bottom: 10px;
}
.error-btn {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  padding: 12px 20px;
  color: #ffffff !important;
  font-size: 13px;
  font-weight: bold;
  margin-top: 5px;
  background: var(--primary-main-pink);
  transition: all 0.2s;
}

.event_topic {
  align-content: center;
  text-align: center;
  padding-top: 2rem;
}

@media only screen and (max-width: 424px) {
  .card {
    height: 300px;
  }
  .errorhead {
    font-size: 16px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 425px) {
  .img404 {
    height: 265px;
  }
  .card {
    height: 465px;
  }
}
@media only screen and (max-width: 320px) {
  .card {
    height: 360px;
  }
  .img404 {
    height: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .event_topic {
    padding-top: 0px;
  }
}
