main {
  font-family: "Libre Franklin", sans-serif;
}

.w-90 {
  width: 90%;
}

.h1-hero-sub-text {
  transform: matrix(1, 0.01, 0, 1, 0, 0);
  background-image: url("../images/Shape.svg");
  background-repeat: no-repeat;
  width: 204.12px;
  height: 80px;
  display: inline-block;
  padding-top: 5px;
}

.shape-bg {
  background-repeat: no-repeat, no-repeat;
  background-image: url("../images/shape-two.png"),
    url("../images/shape-one.png");
  background-position: 80px top, top left;
  background-size: 280px, 200px;
}

.hero-heading {
  max-width: 1142px;
}

.btns-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  & .donate {
    color: #b70569;
  }
}

.carousel {
  border-radius: 52px 52px 0px 0px;
}

.card-body {
  background-repeat: no-repeat;
  border-radius: 24px 24px 0px 0px;

  section {
    border-radius: 24px 24px 0px 0px;
  }
}

.card-body-img-1 {
  background-image: url("../images/homepage/tinypng4.png");
}

.card-body-img-2 {
  background-image: url("../images/homepage/tinypng3.png");
}

.card-body-img-3 {
  background-image: url("../images/homepage/tinypng1.png");
}

.card-body-img-4 {
  background-image: url("../images/homepage/tinypng5.png");
}

.slick-wrapper .slick-slider {
  position: unset;

  .slick-next {
    color: #b70569;
    top: 300px;
    left: 50%;

    &::before {
      display: none;
    }

    &:hover {
      color: #808080;
    }
  }

  .slick-prev {
    color: #b70569;
    left: 45%;
    top: 300px;

    &::before {
      display: none;
    }

    &:hover {
      color: #808080;
    }
  }
}

.hire-us::before {
  position: absolute;
  content: "";
  height: 450px;
  width: 300px;
  background-color: transparent;
  border: 16px solid #b70569;
  z-index: -1;
  top: -65px;
  left: -30px;
}

.custom-slick-img {
  display: flex !important;
  align-items: center;
}

@media only screen and (max-width: 479px) {
  .btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .donate {
      padding: 14px 40px 14px 32px;
    }
  }
}

@media only screen and (max-width: 639px) {
  .slick-wrapper .slick-slider {
    .slick-next {
      left: 50%;
      top: 100px;
    }

    .slick-prev {
      left: 45%;
      top: 100px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .h1-hero-sub-text {
    height: 90px;
    padding-top: 15px;
    background-position: 0 10px;
  }

  .slick-wrapper .slick-slider {
    .slick-next {
      left: 12rem;
      top: 320px;
    }

    .slick-prev {
      left: 10rem;
      top: 320px;
    }
  }

  .hire-us::before {
    height: 590px;
    width: 430px;
    top: -50px;
    left: -50px;
  }
}

@media only screen and (min-width: 992px) {
  .h1-hero-sub-text {
    height: 120px;
    display: inline-block;
    background-position: 0 25px;
  }

  .slick-wrapper .slick-slider {
    .slick-next {
      left: 220px;
      top: 65%;
    }

    .slick-prev {
      left: 200px;
      top: 65%;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .our-reach .right-xl {
    right: -150px;
  }

  .slick-wrapper .slick-slider {
    .slick-next {
      left: 270px;
      top: 65%;
    }

    .slick-prev {
      left: 250px;
      top: 65%;
    }
  }
}
